import { IconContext } from 'react-icons';
import { BsFileEarmarkImage } from 'react-icons/bs';

// イメージファイル用アイコン
const ImgFileIcon: React.FC = () => {
  return (
    //valueにcolorとsizeの値を指定して渡す
    <IconContext.Provider value={{size: '24px',color: '#00A3C8', }}>
      <BsFileEarmarkImage />
    </IconContext.Provider>
  )
}

export default ImgFileIcon