import React from "react";
import { ReactComponent as DownloadMarkIcon } from "../images/download.svg";

// Downloadアイコンを返却
const DownloadIcon: React.FC = () => {
  return (
    <DownloadMarkIcon width={14} height={14} fill={"#B1B0AD"} />
  );
};

export default DownloadIcon;