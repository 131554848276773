import { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";
import { MailAuthInfo, mailAddressCheck } from '../../api/index';
import Api_Status, { Message, RegExpPattern, availableLocales } from '../../constant/constant';
import { useTranslation } from 'react-i18next';
import '../../App.css';
import '../../fgnp.css';

/**********************************************
* メールアドレス入力画面
**********************************************/
export const MailInput = () => {

  // 多言語化
  const { t, i18n } = useTranslation();

  // URLパラメタからコンテナ名とメールアドレス取得
  let { container, inputmail } = useParams();

  // バウンス解除画面から戻ってきた場合は追加パラメタとしてBounceDelAfterフラグが設定されている
  const location = useLocation();
  const [bounceDelState, setBounceDelState]
    = useState<{ bounceDelAfter: boolean }>
      ((location?.state) ? location?.state as { bounceDelAfter: boolean } : { bounceDelAfter: false })
  // console.log("bounceDelState.bounceDelAfter=" + bounceDelState.bounceDelAfter);

  // メールアドレス入力API呼び出し中ステータス管理
  const [isProcessing, setIsProcessing] = useState(false);

  // ブラウザの言語設定によって表示文字言語切り替え
  let userLanguage: string = window.navigator.language || "en"; // デフォルトは英語
  if (userLanguage.length >= 2) {
    userLanguage = userLanguage.substring(0, 2);
  }
  if (!availableLocales.includes(userLanguage)) {
    userLanguage = "en";
  }
  console.log("currentLang=" + userLanguage);
  useEffect(() => {
    i18n.changeLanguage(userLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [i18n])

  // レイアウト調整（縦中央揃え）
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0] as HTMLElement;
    const scriptUrl = document.createElement('script');
    scriptUrl.type = 'text/javascript';
    scriptUrl.innerText = 'console.log("add100HeightClass");add100HeightClass();';
    body.appendChild(scriptUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [])

  // 入力フォーム設定（テキストボックス初期値はURLパラメタから設定）
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<MailAuthInfo>({ defaultValues: { mail: (inputmail == null) ? "" : decodeURIComponent(inputmail), container: (container == null) ? "" : container } });
  const navigate = useNavigate(); //　画面遷移用
  const [message, setMessage] = useState(""); // エラーメッセージ格納用

  // 「次へ」ボタン押下時の処理を定義
  const onSubmit: SubmitHandler<MailAuthInfo> = async (data: MailAuthInfo) => {
    // 処理中ステータスにする
    setIsProcessing(true);
    // 画面に表示されているエラーメッセージはいったんクリア
    setMessage("");
    // 画面に表示されていないパラメタ(前画面から引き継がれた、パスワード以外のパラメタ)をAPIへのパラメタに追加
    // data.container = container;
    // メールアドレス入力処理API呼び出し
    const response: any = await mailAddressCheck(data);
    // 通信エラー、サーバーエラー（status:200以外）
    if (response == null || (response.status && response.status !== 200)) {
      setMessage(Message.SYSTEM_ERROR);
      // 処理完了ステータスにする
      setIsProcessing(false);
      return;
    }
    // API呼び出し結果取得
    const json = response.data;
    if (json.status === Api_Status.OK || json.status === Api_Status.MAIL_SEND_ERROR) {  // 正常またはメール送信エラー
      // 処理完了ステータスにする
      setIsProcessing(false);
      //ワンタイムパスワード入力画面にリダイレクト
      navigate('/passwordInput/', { state: { container: data.container, mail: data.mail, status: json.status, inputMail: ((inputmail == null) ? "" : inputmail) } })
    } else if (json.status === Api_Status.CONTAINER_NOTFOUND) { // コンテナ登録なし
      setMessage(Message.CONTAINER_NOTFOUND)
    } else if (json.status === Api_Status.MAIL_NOTFOUND) {  // メールアドレス未登録
      setMessage(Message.MAIL_NOTFOUND)
    } else if (json.status === Api_Status.PASSCODE_CREATE_ERROR) {  // ワンタイムパスワード生成セラー
      setMessage(Message.PASSCODE_CREATE_ERROR)
      // } else if (json.status === Api_Status.MAIL_SEND_ERROR) {  // メール送信エラー
      //   setMessage(Message.MAIL_SEND_ERROR)
    } else if (json.status === Api_Status.PASSCODE_LOCK_ERROR) {  // パスワードロック中エラー
      setMessage(Message.PASSCODE_LOCK_ERROR)
    } else if (json.status === Api_Status.PASSCODE_CREATE_LOCK_ERROR) {  // パスワード発行ロック中エラー
      setMessage(Message.PASSCODE_CREATE_LOCK_ERROR)
    } else if (json.status === Api_Status.MAIL_ADDRESS_BOUNCE_ERROR) {  // メールアドレスバウンスエラー
      // 処理完了ステータスにする
      setIsProcessing(false);
      //バウンス解除画面にリダイレクト
      navigate('/bounceDelete/', { state: { container: data.container, mail: data.mail, inputMail: ((inputmail == null) ? "" : inputmail) } })
    } else if (json.status === Api_Status.UNDER_MAINTENANCE) {  // メンテナンス中
      setMessage(Message.UNDER_MAINTENANCE)
    } else {  // その他（システムエラー）
      setMessage(Message.SYSTEM_ERROR)
    }
    // 処理完了ステータスにする
    setIsProcessing(false);
  };

  // エラーメッセージ表示領域の定義
  const errorMessageView = (validationMessage?: string, serverMessage?: string) => {
    if (validationMessage) {
      return <div className="errorMessage">{validationMessage}</div>;
    } else if (serverMessage) {
      return <div className="errorMessage">{serverMessage}</div>;
    }
  };

  // メールアドレス入力テキストボックスをreact-hook-formのuseFormと連携させるための定義
  const mailRegister = register("mail",
    {
      required: Message.MAIL_REQUIRED,
      pattern: {
        value: RegExpPattern.EmailVaildPattern,
        message: Message.MAIL_FORMAT_ERROR,
      },
    });

  // 画面描画
  return (
    <>

      {isProcessing &&
        <div id="processing" className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Processing...</span>
          </Spinner>
        </div>
      }

      <div id="login-form" className="d-flex flex-grow-1 flex-shrink-0 align-items-sm-center justify-content-center py-sm-4 login-form-margin" >
        <div className="col col-sm-auto card flex-row px-0">
          <div id="bounceDelForm" className="pt-7 pb-8 px-6 px-sm-7 mx-auto" style={{ width: 476 + 'px' }}>
            <div className="px-0 px-sm-1">
              <div id="bounceDelLogo" className="d-flex mb-7">
                <span className="fujitsu-logo mx-auto" style={{ width: 93 + 'px' }}></span>
              </div>
              <h1 id="bounceDelheaderTitle" className="h3 mb-6 AuthTitleHeader">
                {t("headerTitle")}
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                {bounceDelState.bounceDelAfter &&
                  <div className="passcodeSendMessage">{t("bounceDeleteAfterMessage")}</div>
                }
                <div className="AuthTitleComment">{t("AuthTitleComment")}</div>
                <div className="form-group">
                  <input className="form-control"
                    id="mail"
                    placeholder={Message.MAIL_REQUIRED}
                    {...mailRegister}
                    onChange={(e) => {
                      mailRegister.onChange(e);
                      setMessage("");
                    }}
                  // onKeyDown={(e) => {
                  //   //   if (e.key === 'Enter') e.preventDefault();
                  // }}
                  />
                </div>
                <button id="nextBtn" type="submit" className="btn btn-lg btn-primary btn-block mb-4" disabled={isSubmitting}>{t("btnNext")}
                </button>
                <input
                  id="container"
                  type="hidden"
                  {...register("container")}
                />
                <div className="AuthCommentSubMessage">{t("permissionToReceiveMailMessage01")}</div>
                <div className="AuthCommentSubMessage">{t("permissionToReceiveMailMessage02")}</div>
                <div className="AuthCommentSubMessage">-&nbsp;{t("permissionToReceiveMailMessage03")}</div>
                <div className="AuthCommentSubMessage">-&nbsp;{t("permissionToReceiveMailMessage04")}</div>
                <br />
                {errorMessageView(errors.mail?.message, message)}
                {/* {errors.mail?.message && <div className="errorMessage">{errors.mail.message}</div>}
                {(!(errors.mail?.message) && message) && <div className="errorMessage">{message}</div>} */}
              </form>
            </div>
          </div>
        </div>
      </div>

      <footer
        className="position-relative d-flex flex-column flex-shrink-0 align-items-center justify-content-sm-between py-3 px-4 text-dark bg-white bg-sm-transparent">
        <div>
          © Fujitsu Limited 2023
        </div>
      </footer>

    </>
  );
};
