// ファイルサイズ換算
export const fileSizeUnitConcert = (
  size: number,
  unitBase?: boolean,
  round?: number
): string => {
  
  let thisSize: number;
  let fileUnit: string[];
  let thisUnit: number;
  let unitBaseNum: number;

  //引数の数値を換算用変数に代入
  thisSize = size;

  //数値に変換できなかった場合
  if (isNaN(thisSize)) {
    return "{Not a Number}";
  }

  //小数点を含めている場合
  if (String(thisSize).split(".").length > 1) {
    return "{Unaccetable Number}";
  }

  //基準のバイト数と単位の配列を設定
  if (unitBase) {
    unitBaseNum = 1000;
    fileUnit = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  } else {
    unitBaseNum = 1024;
    // fileUnit = ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    fileUnit = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  }

  //有効小数点 デフォルト小数第2位まで(小数第3位で四捨五入)
  if (round !== 0) {
    round = round ? round : 2;
  }

  //数値の大きさに基づき最適な単位で換算
  if (thisSize >= unitBaseNum) {
    thisUnit = 0;
    for (
      let i = 0, j = 0, sizeTemp = thisSize / unitBaseNum;
      sizeTemp >= 1 && j < fileUnit.length;
      i++, j++, sizeTemp /= unitBaseNum
    ) {
      thisUnit = i;
      thisSize = sizeTemp;
    }
    let returnSize:string = (Math.round(thisSize * (10**round))/(10**round))+' '+fileUnit[thisUnit];
    return returnSize;
  } else {
    if (thisSize === 1) {
      return `${thisSize} byte`;
    } else {
      return `${thisSize} bytes`;
    }
  }
};

// 日付フォーマット変換（YYYY/MM/DD HH:MI:SS形式）
export const dateFormatConcertToLocalDate = (
  date: Date
): string => {
  if (!date) return "";
  const year:string = date.getFullYear().toString().padStart(4, '0');
  const month:string = (date.getMonth() + 1).toString().padStart(2, '0');
  const day:string = date.getDate().toString().padStart(2, '0');
  const hour:string = date.getHours().toString().padStart(2, '0');
  const minute:string = date.getMinutes().toString().padStart(2, '0');
  const second:string = date.getSeconds().toString().padStart(2, '0');
  return  `${year}/${month}/${day} ${hour}:${minute}:${second}`;
}

// 文字列エスケープ処理（XSS対策）
export const escapeHtml = (unsafeText:string): string => {
  console.log("unsafeText=" + unsafeText);
  let safeText:string = unsafeText.replace(/&/g, '&amp;');
  safeText = safeText.replace(/</g, '&lt;');
  safeText = safeText.replace(/>/g, '&gt;');
  safeText = safeText.replace(/"/g, '&quot;');
  safeText = safeText.replace(/'/g, '&#39;');
  console.log("safeText=" + safeText);
  return safeText;
}

// ファイルの拡張子に応じたアイコンファイル名を返却
export const getIconName = (filename:string): string => {
  // ファイル名が空の場合
  if (!filename) {
    return "file-outline";
  }
  // ファイル名を拡張子「.」で分割
  let filenameSplit:string[] =  filename.split('.');
  // 拡張子無し
  if (filenameSplit.length === 1) {
    return "file-outline";
  }
  // 拡張子取得
  let extension:string = filenameSplit[filenameSplit.length-1].toUpperCase();
  // ファイル拡張子から該当するアイコンファイル名を取得
  let iconName:string; // アイコンファイル名
  switch (extension){
    case "ZIP":
    case "TAR":
    case "JAR":
    case "GZ":
    case "RAR":
    case "7Z":
    case "CAB":
    case "LZH":
      iconName = "folder-zipped";
      break;
    case "HTML":
      iconName = "file-html";
      break;
    case "PDF":
      iconName = "file-pdf";
      break;
    case "DOC":
    case "DOCM":
    case "DOCX":
    case "DOTM":
    case "DOTX":
    case "RTF":
      iconName = "file-word";
      break;
    case "XLSX":
    case "XLSM":
    case "XLSB":
    case "XLTX":
    case "XLTM":
    case "XLS":
    case "XLT":
    case "XLAM":
    case "XLA":
    case "XLW":
    case "XLR":
      iconName = "file-excel";
      break;
    case "LOG":
      iconName = "log";
      break;
    default:
      iconName = "file-outline";
  }
  return iconName;
}


