import React from "react";
import { ReactComponent as FileHtmlIcon } from "../images/file-html.svg";
import { ReactComponent as FilePdfIcon } from "../images/file-pdf.svg";
import { ReactComponent as FileWordIcon } from "../images/file-word.svg";
import { ReactComponent as FileZipIcon } from "../images/folder-zipped.svg";
import { ReactComponent as FileLogIcon } from "../images/log.svg";
import { ReactComponent as FileExcelIcon } from "../images/file-excel.svg";
import { ReactComponent as FilePptIcon } from "../images/file-ppt.svg";
import PptFileIcon from "./PptFileIcon";
import ImgFileIcon from "./ImgFileIcon";
import TxtFileIcon from "./TxtFileIcon";
import OtherFileIcon from "./OtherFileIcon";

interface Props {
  filename: string;
}

// ファイルの拡張子に応じたアイコンを返却
const FileIcon: React.FC<Props> = ({ filename }) => {
  // ファイル名が空の場合
  if (!filename) {
    // return (<FileOtherIcon width={24} height={24} /> );
    return (<OtherFileIcon />);
  }
  // ファイル名を拡張子「.」で分割
  let filenameSplit: string[] = filename.split('.');
  // 拡張子無し
  if (filenameSplit.length === 1) {
    // return (<FileOtherIcon width={24} height={24} /> );
    return (<OtherFileIcon />);
  }
  // 拡張子取得
  let extension: string = filenameSplit[filenameSplit.length - 1].toUpperCase();
  // ファイル拡張子から該当するアイコンファイル名を取得
  switch (extension) {
    case "ZIP":
    case "TAR":
    case "JAR":
    case "GZ":
    case "RAR":
    case "7Z":
    case "CAB":
    case "LZH":
      return (<FileZipIcon width={24} height={24} fill={"#F5CA14"} />);
    case "HTML":
      return (<FileHtmlIcon width={24} height={24} fill={"#024C9E"} />);
    case "PDF":
      return (<FilePdfIcon width={24} height={24} fill={"#C90C1F"} />);
    case "DOC":
    case "DOCM":
    case "DOCX":
    case "DOTM":
    case "DOTX":
    case "RTF":
      return (<FileWordIcon width={24} height={24} fill={"#0D67CB"} />);
    case "XLSX":
    case "XLSM":
    case "XLSB":
    case "XLTX":
    case "XLTM":
    case "XLS":
    case "XLT":
    case "XLAM":
    case "XLA":
    case "XLW":
    case "XLR":
      return (<FileExcelIcon width={24} height={24} fill={"#006644"} />);
    case "LOG":
      return (<FileLogIcon width={24} height={24} />);
    case "TXT":
    case "TEXT":
    case "CSS":
    case "XML":
    case "JS":
    case "CSV":
      // return (<FileTextIcon width={24} height={24} /> );
      return (<TxtFileIcon />);
    case "PPT":
    case "PPTX":
      // return (<PptFileIcon />);
      return (<FilePptIcon width={24} height={24} fill={"#C90C1F"} />);
    case "JPG":
    case "JPEG":
    case "PNG":
    case "GIF":
    case "PSD":
    case "TIF":
    case "TIFF":
    case "BMP":
    case "TGA":
      return (<ImgFileIcon />);
    default:
      // return (<FileOtherIcon width={24} height={24} /> );
      return (<OtherFileIcon />);
  }
};

export default FileIcon;