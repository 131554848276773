import { IconContext } from 'react-icons';
import { BsEyeSlashFill } from 'react-icons/bs';

// パスワード伏字アイコン
const EyeSlashIcon: React.FC = () => {
  return (
    //valueにcolorとsizeの値を指定して渡す
    <IconContext.Provider value={{size: '1.5em' }}>
      <BsEyeSlashFill />
    </IconContext.Provider>
  )
}

export default EyeSlashIcon