import { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { MailAuthInfo, bounceDelete } from '../../api/index';
import Api_Status, { Message, availableLocales } from "../../constant/constant";
import { useTranslation } from 'react-i18next';
import '../../App.css';
import '../../fgnp.css';

/**********************************************
* バウンス解除画面
**********************************************/
export const BounceDelete = () => {

  // 多言語化
  const { t, i18n } = useTranslation();

  // 前画面からパラメタ引継ぎ
  const location = useLocation();
  type mailInfoStateType = { container: string, mail: string, inputMail?: string };
  const mailInfoState = location.state as mailInfoStateType;
  const [message, setMessage] = useState(""); // エラーメッセージ格納用

  // console.log("container=" + mailInfoState?.container);
  // console.log("mail=" + mailInfoState?.mail);
  // console.log("inputMail=" + mailInfoState?.inputMail);

  // 入力フォーム設定
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<MailAuthInfo>();

  // 画面遷移用
  const navigate = useNavigate();

  // 送信エラー登録解除申請中ステータス管理
  const [isProcessing, setIsProcessing] = useState(false);

  // ブラウザの言語設定によって表示文字言語切り替え
  let userLanguage: string = window.navigator.language || "en"; // デフォルトは英語
  if (userLanguage.length >= 2) {
    userLanguage = userLanguage.substring(0, 2);
  }
  if (!availableLocales.includes(userLanguage)) {
    userLanguage = "en";
  }
  console.log("currentLang=" + userLanguage);
  useEffect(() => {
    i18n.changeLanguage(userLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [i18n])

  // 認証状態確認
  useEffect(() => {
    // 画面レイアウト調整
    const body = document.getElementsByTagName('body')[0] as HTMLElement;
    const scriptUrl = document.createElement('script');
    scriptUrl.type = 'text/javascript';
    scriptUrl.innerText = 'console.log("add100HeightClass");add100HeightClass();';
    body.appendChild(scriptUrl);
    // コンテナ名とメールアドレスが前画面から引き継げてなかったら不正な画面遷移とみなす
    if (!mailInfoState || !(mailInfoState.container) || !(mailInfoState.mail)) {
      // メールアドレス入力画面に戻る
      alert(Message.UNAUTHORIZED_ERROR);
      let parmMail: string = (mailInfoState?.mail) ? encodeURIComponent(mailInfoState?.mail) : "";
      if (mailInfoState && mailInfoState?.container && mailInfoState?.container.length > 0) {
        navigate(`/mailcheck/${mailInfoState.container}/${parmMail}`);
      } else {
        let undefineContainer: string = "undefined";
        navigate(`/mailcheck/${undefineContainer}/${parmMail}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, []);

  // 「送信エラー登録解除申請」ボタン押下時の処理を定義
  const onSubmit: SubmitHandler<MailAuthInfo> = async (data) => {
    // 処理中ステータスにする
    setIsProcessing(true);
    // 画面に表示されているエラーメッセージはいったんクリア
    setMessage("");
    // 画面に表示されていないパラメタ(前画面から引き継がれたパラメタ)をAPIへのパラメタに追加
    data.container = mailInfoState.container;
    data.mail = mailInfoState.mail;
    // バウンス解除処理API呼び出し
    const response: any = await bounceDelete(data);
    // 通信エラー、サーバーエラー（status:200以外）
    if (response == null || (response.status && response.status !== 200)) {
      setMessage(Message.SYSTEM_ERROR);
      // 処理完了ステータスにする
      setIsProcessing(false);
      return;
    }
    // API呼び出し結果取得
    const json = response.data;
    if (json.status === Api_Status.OK) {  // 正常
      // 処理完了ステータスにする
      setIsProcessing(false);
      // メールアドレス入力画面に戻る
      let parmMail: string = (data.mail) ? encodeURIComponent(data.mail) : "";
      navigate(`/mailcheck/${data.container}/${parmMail}`, { state: { bounceDelAfter: true } });
      //バウンス解除完了画面にリダイレクト
      // navigate('/bounceDeleteFinish/',
      //   { state: { container: data.container, mail: data.mail, inputMail: mailInfoState.inputMail } })
    } else if (json.status === Api_Status.CONTAINER_NOTFOUND) { // コンテナ登録なし
      setMessage(Message.CONTAINER_NOTFOUND)
    } else if (json.status === Api_Status.MAIL_NOTFOUND) {  // メールアドレス未登録
      setMessage(Message.MAIL_NOTFOUND)
    } else if (json.status === Api_Status.MAIL_ADDRESS_BOUNCE_NOTHIING_ERROR) {  // メールアドレスがバウンスリストに存在しない
      setMessage(Message.MAIL_ADDRESS_BOUNCE_NOTHIING_ERROR)
    } else if (json.status === Api_Status.MAIL_ADDRESS_BOUNCE_LIST_DELETE_ERROR) {  // バウンスリストからの削除時にエラー
      setMessage(Message.MAIL_ADDRESS_BOUNCE_LIST_DELETE_ERROR)
    } else if (json.status === Api_Status.UNDER_MAINTENANCE) {  // メンテナンス中
      setMessage(Message.UNDER_MAINTENANCE)
    } else {  // その他（システムエラー）
      setMessage(Message.SYSTEM_ERROR)
    }
    // 処理完了ステータスにする
    setIsProcessing(false);
  };

  // 「キャンセル」リンク押下時の処理を定義
  const cancel = () => {
    // メールアドレス入力画面に戻る
    let parmMail: string = (mailInfoState.mail) ? encodeURIComponent(mailInfoState.mail) : "";
    navigate(`/mailcheck/${mailInfoState.container}/${parmMail}`, { state: { bounceDelAfter: false } });
  };

  // エラーメッセージ表示領域の定義
  const errorMessageView = (serverMessage?: string) => {
    return <div className="errorMessage">{serverMessage}</div>;
  };

  // 画面描画
  return (
    <>

      {isProcessing &&
        <div id="processing" className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Processing...</span>
          </Spinner>
        </div>
      }

      <div id="login-form" className="d-flex flex-grow-1 flex-shrink-0 align-items-sm-center justify-content-center py-sm-4 login-form-margin" >
        <div className="col col-sm-auto card flex-row px-0">
          <div id="bounceDelForm" className="pt-7 pb-8 px-6 px-sm-7 mx-auto" style={{ width: 476 + 'px' }}>
            <div className="px-0 px-sm-1">
              <div id="bounceDelLogo" className="d-flex mb-7">
                <span className="fujitsu-logo mx-auto" style={{ width: 93 + 'px' }}></span>
              </div>
              <h1 id="bounceDelheaderTitle" className="h3 mb-6 AuthTitleHeader">
                {t("headerTitle")}
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className='AuthTitleComment'>
                    {/* {t("occursBounceMessage01", { mail: ((mailInfoState?.mail) ? mailInfoState.mail : "") })} */}
                    {t("occursBounceMessage01_1")}<span style={{ wordBreak: "break-all" }}>{(mailInfoState?.mail) ? mailInfoState.mail : ""}</span>{t("occursBounceMessage01_2")}
                  </div>
                  <div className='AuthTitleComment'>
                    {t("occursBounceMessage02")}
                  </div>
                </div>
                {/* <div><button type="button" className="link-button" onClick={() => cancel()} >{t("cancel")}</button></div> */}
                {/* <br /> */}
                <div>
                  <button id="bounceDeleteBtn" type="submit" className="btn btn-lg btn-primary btn-block mb-4" disabled={isSubmitting}>
                    {t("btnBounceDelete")}
                  </button>
                  {/* <div><button type="button" className="link-button" onClick={() => cancel()} >{t("cancel")}</button></div> */}
                </div>
                <div><button type="button" className="link-button" onClick={() => cancel()} >{t("cancel")}</button></div><br />
                <div>
                  <div className="AuthCommentSubMessage">{t("permissionToReceiveMailMessage01ForBounceAvoidance")}</div>
                  <div className="AuthCommentSubMessage">{t("permissionToReceiveMailMessage02")}</div>
                  <div className="AuthCommentSubMessage">-&nbsp;{t("permissionToReceiveMailMessage03")}</div>
                  <div className="AuthCommentSubMessage">-&nbsp;{t("permissionToReceiveMailMessage04")}</div>
                </div>
                <br />
                <div className="messageParent">
                  {errorMessageView(message)}
                </div>
              </form>
            </div>
          </div>
        </div >
      </div >

      <footer
        className="position-relative d-flex flex-column flex-shrink-0 align-items-center justify-content-sm-between py-3 px-4 text-dark bg-white bg-sm-transparent">
        <div>
          © Fujitsu Limited 2023
        </div>
      </footer>

    </>
  );

};