import React from "react";
import { ReactComponent as LogoutMarkIcon } from "../images/logout.svg";

// Logoutアイコンを返却
const LogoutIcon: React.FC = () => {  
  return (
    <LogoutMarkIcon width={24} height={24} fill={"#2B2A28"} />
  );
};

export default LogoutIcon;
