import { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { MailAuthInfo, passcodeCheck, mailAddressCheck } from '../../api/index';
import Api_Status, { Message, RegExpPattern, availableLocales } from "../../constant/constant";
import EyeIcon from '../../components/EyeIcon';
import EyeSlashIcon from '../../components/EyeSlashIcon';
import { useTranslation } from 'react-i18next';
import '../../App.css';
import '../../fgnp.css';

/**********************************************
* ワンタイムパスワード入力画面
**********************************************/
export const PasswordInput = () => {

  // 多言語化
  const { t, i18n } = useTranslation();

  // 前画面からパラメタ引継ぎ
  const location = useLocation();
  type AuthInfoStateType = { container: string, mail: string, status?: string, inputMail?: string };
  const authInfoState = location.state as AuthInfoStateType;
  const [message, setMessage] = useState(""); // エラーメッセージ格納用
  const [passcodeSendMessage, setPasscodeSendMessage]
    = useState((authInfoState?.status && authInfoState.status === Api_Status.OK) ? Message.PASSCODE_SEND : "");

  // パスワード表示・非表示切り替え設定用
  const [isRevealPassword, setIsRevealPassword] = useState(false);

  // 入力フォーム設定
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<MailAuthInfo>();

  // 画面遷移用
  const navigate = useNavigate();

  // パスワード再送信中ステータス管理
  const [isProcessing, setIsProcessing] = useState(false);

  // ブラウザの言語設定によって表示文字言語切り替え
  let userLanguage: string = window.navigator.language || "en"; // デフォルトは英語
  if (userLanguage.length >= 2) {
    userLanguage = userLanguage.substring(0, 2);
  }
  if (!availableLocales.includes(userLanguage)) {
    userLanguage = "en";
  }
  console.log("currentLang=" + userLanguage);
  useEffect(() => {
    i18n.changeLanguage(userLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [i18n])

  // 認証状態確認
  useEffect(() => {
    // 画面レイアウト調整
    const body = document.getElementsByTagName('body')[0] as HTMLElement;
    const scriptUrl = document.createElement('script');
    scriptUrl.type = 'text/javascript';
    scriptUrl.innerText = 'console.log("add100HeightClass");add100HeightClass();';
    body.appendChild(scriptUrl);
    // コンテナ名とメールアドレスが前画面から引き継げてなかったら不正な画面遷移とみなす
    if (!authInfoState || !(authInfoState.container) || !(authInfoState.mail)) {
      // メールアドレス入力画面に戻る
      alert(Message.UNAUTHORIZED_ERROR);
      let parmMail: string = (authInfoState?.mail) ? encodeURIComponent(authInfoState?.mail) : "";
      if (authInfoState && authInfoState?.container && authInfoState?.container.length > 0) {
        navigate(`/mailcheck/${authInfoState.container}/${parmMail}`);
      } else {
        let undefineContainer: string = "undefined";
        navigate(`/mailcheck/${undefineContainer}/${parmMail}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, []);

  // パスワード表示/非表示切り替え用Eyeマークアイコンをクリックしたときの処理（表示・非表示切替）
  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  }

  // 「確認」ボタン押下時の処理を定義
  const onSubmit: SubmitHandler<MailAuthInfo> = async (data) => {
    // 処理中ステータスにする
    setIsProcessing(true);
    // 画面に表示されているエラーメッセージはいったんクリア
    setMessage("");
    // 画面に表示されていないパラメタ(前画面から引き継がれた、パスワード以外のパラメタ)をAPIへのパラメタに追加
    data.container = authInfoState.container;
    data.mail = authInfoState.mail;
    // ワンタイムパスワード入力処理API呼び出し
    const response: any = await passcodeCheck(data);
    // 通信エラー、サーバーエラー（status:200以外）
    if (response == null || (response.status && response.status !== 200)) {
      // 処理完了ステータスにする
      setIsProcessing(false);
      setMessage(Message.SYSTEM_ERROR);
      return;
    }
    // API呼び出し結果取得
    const json = response.data;
    if (json.status === Api_Status.OK) {  // 正常
      // 処理完了ステータスにする
      setIsProcessing(false);
      //ファイル一覧画面にリダイレクト
      navigate('/downloadFileList/',
        { state: { container: data.container, storageAccount: json.storageAccount, token: json.token, mail: data.mail, inputMail: authInfoState.inputMail } })
    } else if (json.status === Api_Status.CONTAINER_NOTFOUND) { // コンテナ登録なし
      setMessage(Message.CONTAINER_NOTFOUND)
    } else if (json.status === Api_Status.MAIL_NOTFOUND) {  // メールアドレス未登録
      setMessage(Message.MAIL_NOTFOUND)
    } else if (json.status === Api_Status.PASSCODE_AUTH_ERROR) {  // ワンタイムパスワード認証セラー
      setMessage(Message.PASSCODE_AUTH_ERROR)
    } else if (json.status === Api_Status.SAS_TOKEN_CREATE_ERROR) {  // SASトークン生成セラー
      setMessage(Message.SAS_TOKEN_CREATE_ERROR)
    } else if (json.status === Api_Status.PASSCODE_LOCK_ERROR) {  // パスワードロック中エラー
      setMessage(Message.PASSCODE_LOCK_ERROR)
    } else if (json.status === Api_Status.UNDER_MAINTENANCE) {  // メンテナンス中
      setMessage(Message.UNDER_MAINTENANCE)
    } else {  // その他（システムエラー）
      setMessage(Message.SYSTEM_ERROR)
    }
    // 処理完了ステータスにする
    setIsProcessing(false);
  };

  // 「再送信」リンク押下時の処理を定義
  const mailCheckRetry = async () => {
    // 処理中ステータスにする
    setIsProcessing(true);
    // 前画面から引き継がれたコンテナ名とメールアドレスをパラメタに設定してメールアドレス入力処理API呼び出し
    const data: MailAuthInfo = { container: authInfoState.container, mail: authInfoState.mail };
    const response: any = await mailAddressCheck(data);
    // 処理完了ステータスにする
    setIsProcessing(false);
    // 通信エラー、サーバーエラー（status:200以外）
    if (response == null || (response.status && response.status !== 200)) {
      setMessage(Message.SYSTEM_ERROR);
      return;
    }
    // API呼び出し結果取得
    const json = response.data;
    if (json.status === Api_Status.OK) {  // 正常
      // ワンタイムパスワード通知メール再送信を行ったことを通知
      setMessage("");
      alert(Message.PASSCODE_SEND_RETRY);
      setPasscodeSendMessage(Message.PASSCODE_SEND);
    } else if (json.status === Api_Status.CONTAINER_NOTFOUND) { // コンテナ登録なし
      setMessage(Message.CONTAINER_NOTFOUND)
    } else if (json.status === Api_Status.MAIL_NOTFOUND) {  // メールアドレス未登録
      setMessage(Message.MAIL_NOTFOUND)
    } else if (json.status === Api_Status.PASSCODE_CREATE_ERROR) {  // ワンタイムパスワード生成セラー
      setMessage(Message.PASSCODE_CREATE_ERROR)
    } else if (json.status === Api_Status.MAIL_SEND_ERROR) {  // メール送信エラー
      setMessage("");
      alert(Message.PASSCODE_SEND_RETRY);
      authInfoState.status = Api_Status.MAIL_SEND_ERROR;
      setPasscodeSendMessage("");
      // setMessage(Message.MAIL_SEND_ERROR)
    } else if (json.status === Api_Status.PASSCODE_LOCK_ERROR) {  // パスワードロック中エラー
      setMessage(Message.PASSCODE_LOCK_ERROR)
    } else if (json.status === Api_Status.PASSCODE_CREATE_LOCK_ERROR) {  // パスワード発行ロック中エラー
      setMessage(Message.PASSCODE_CREATE_LOCK_ERROR)
    } else if (json.status === Api_Status.MAIL_ADDRESS_BOUNCE_ERROR) {  // メールアドレスバウンスエラー
      //バウンス解除画面にリダイレクト
      navigate('/bounceDelete/', { state: { container: data.container, mail: data.mail, inputMail: authInfoState.inputMail } })
    } else if (json.status === Api_Status.UNDER_MAINTENANCE) {  // メンテナンス中
      setMessage(Message.UNDER_MAINTENANCE)
    } else {  // その他（システムエラー）
      setMessage(Message.SYSTEM_ERROR)
    }
  }

  // エラーメッセージ表示領域の定義
  const errorMessageView = (validationMessage?: string, serverMessage?: string) => {
    if (validationMessage) {
      return <div className="errorMessage">{validationMessage}</div>;
    } else if (serverMessage) {
      return <div className="errorMessage">{serverMessage}</div>;
    }
  };

  // パスワード入力テキストボックスをreact-hook-formのuseFormと連携させるための定義
  const passcodeRegister = register("passcode",
    {
      required: Message.PASSCODE_REQUIRED,
      pattern: {
        value: RegExpPattern.PasscodeVaildPattern,
        message: Message.PASSCODE_AUTH_ERROR,
      },
    });

  // 画面描画
  return (
    <>

      {isProcessing &&
        <div id="processing" className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Processing...</span>
          </Spinner>
        </div>
      }

      <div id="login-form" className="d-flex flex-grow-1 flex-shrink-0 align-items-sm-center justify-content-center py-sm-4 login-form-margin" >
        <div className="col col-sm-auto card flex-row px-0">
          <div id="bounceDelForm" className="pt-7 pb-8 px-6 px-sm-7 mx-auto" style={{ width: 476 + 'px' }}>
            <div className="px-0 px-sm-1">
              <div id="bounceDelLogo" className="d-flex mb-7">
                <span className="fujitsu-logo mx-auto" style={{ width: 93 + 'px' }}></span>
              </div>
              <h1 id="bounceDelheaderTitle" className="h3 mb-6 AuthTitleHeader">
                {t("headerTitle")}
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="passcodeSendMessage">{passcodeSendMessage}</div>
                <div className='AuthTitleComment'>
                  {/* {t("passcodeInputRequestMessage", { mail: ((authInfoState?.mail) ? authInfoState.mail : "") })} */}
                  {t("passcodeInputRequestMessage_1")}<span style={{ wordBreak: "break-all" }}>{(authInfoState?.mail) ? authInfoState.mail : ""}</span>{t("passcodeInputRequestMessage_2")}
                  &nbsp;
                  <button type="button" className="link-button" onClick={() => mailCheckRetry()} >{t("mailCheckRetry")}</button>
                </div>
                {(!passcodeSendMessage) &&
                  <div className='AuthTitleComment'>
                    {t("mailSendErrorRetryMessage01")}
                    <button type="button" className="link-button" onClick={() => mailCheckRetry()} >{t("mailSendErrorRetryMessage02")}</button>
                    {t("mailSendErrorRetryMessage03")}
                  </div>
                }
                <div className="form-group" style={{ position: 'relative' }}>
                  <input type={isRevealPassword ? 'text' : 'password'}
                    id="passcode" className="form-control"
                    placeholder={Message.PASSCODE_REQUIRED_PLACEFOLDER}
                    {...passcodeRegister}
                    onChange={(e) => {
                      passcodeRegister.onChange(e);
                      setMessage("");
                    }}
                  />
                  <span onClick={togglePassword} role="presentation" className="PasswordReveal">
                    {isRevealPassword ? (
                      <EyeIcon />
                    ) : (
                      <EyeSlashIcon />
                    )}
                  </span>
                </div>
                <br />
                <div>
                  <button id="confirmBtn" type="submit" className="btn btn-lg btn-primary btn-block mb-4" disabled={isSubmitting}>{t("btnConfirm")}</button>
                </div>
                <div>
                  <div className="AuthCommentSubMessage">{t("permissionToReceiveMailMessage05")}</div>
                  <div className="AuthCommentSubMessage">{t("permissionToReceiveMailMessage02")}</div>
                  <div className="AuthCommentSubMessage">-&nbsp;{t("permissionToReceiveMailMessage03")}</div>
                  <div className="AuthCommentSubMessage">-&nbsp;{t("permissionToReceiveMailMessage04")}</div>
                </div>
                <br />
                <div className="messageParent">
                  {errorMessageView(errors.passcode?.message, message)}
                  {/* {errors.passcode?.message && <div className="errorMessage">{errors.passcode.message}</div>}
                  {message && <div className="errorMessage">{message}</div>} */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <footer
        className="position-relative d-flex flex-column flex-shrink-0 align-items-center justify-content-sm-between py-3 px-4 text-dark bg-white bg-sm-transparent">
        <div>
          © Fujitsu Limited 2023
        </div>
      </footer>
    </>
  );

};