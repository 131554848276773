import { IconContext } from 'react-icons';
import { FcFile } from 'react-icons/fc';

// ファイル一覧ヘッダ用アイコン
const HeaderFileIcon: React.FC = () => {
  return (
    //valueにcolorとsizeの値を指定して渡す
    <IconContext.Provider value={{size: '24px' }}>
      <FcFile />
    </IconContext.Provider>
  )
}

export default HeaderFileIcon