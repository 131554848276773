import { IconContext } from 'react-icons';
import { BsEyeFill } from 'react-icons/bs';

// パスワード表示アイコン
const EyeIcon: React.FC = () => {
  return (
    //valueにcolorとsizeの値を指定して渡す
    <IconContext.Provider value={{size: '1.5em' }}>
      <BsEyeFill />
    </IconContext.Provider>
  )
}

export default EyeIcon