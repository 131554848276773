import { useState, useEffect, Fragment } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";
import { ContainerClient, BlobClient, newPipeline, BlobItem } from '@azure/storage-blob';
import Api_Status, { Message, availableLocales } from '../../constant/constant';
import { useTranslation } from 'react-i18next';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MailAuthInfo } from '../../api/index';
import '../../App.css';
import '../../fgnp.css';
import '../../fgnp-icons.css';
import { fileSizeUnitConcert, dateFormatConcertToLocalDate } from '../../utils';
import FileIcon from '../../components/FileIcon';
import HeaderFileIcon from '../../components/HeaderFileIcon';
import DownloadIcon from '../../components/DownloadIcon';
import LogoutIcon from '../../components/LogoutIcon';
import { networkInterfaces } from 'os';

/**********************************************
* ファイル一覧画面
**********************************************/
export const DownloadFileList = () => {

  // 多言語化
  const { t, i18n } = useTranslation();

  // 画面遷移用
  const navigate = useNavigate();

  // ファイル一覧取得中ステータス管理
  const [isProcessing, setIsProcessing] = useState(false);

  // 前画面からパラメタ引継ぎ
  const location = useLocation();
  type StorageAccessInfoType = { container: string, storageAccount: string, token: string, mail: string, inputMail?: string };
  const storageAccessInfoType = location.state as StorageAccessInfoType;
  const [container, setContainer] = useState((storageAccessInfoType?.container) ? storageAccessInfoType.container : "");  // コンテナ名
  const [mail, setMail] = useState((storageAccessInfoType?.mail) ? storageAccessInfoType.mail : "");  // メールアドレス 
  const [storageAccount, setStorageAccount] = useState((storageAccessInfoType?.storageAccount) ? storageAccessInfoType.storageAccount : ""); // ストレージアカウント名
  const [token, setToken] = useState((storageAccessInfoType?.token) ? storageAccessInfoType.token : "");  // SASトークン

  // ブラウザの言語設定によって表示文字言語切り替え
  let userLanguage: string = window.navigator.language || "en"; // デフォルトは英語
  if (userLanguage.length >= 2) {
    userLanguage = userLanguage.substring(0, 2);
  }
  if (!availableLocales.includes(userLanguage)) {
    userLanguage = "en";
  }
  console.log("currentLang=" + userLanguage);
  useEffect(() => {
    i18n.changeLanguage(userLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [i18n])

  // エラーメッセージ格納用
  const [message, setMessage] = useState("");

  // AzureStorageから取得したBlob情報一覧格納用
  const [blobList, setBlobList] = useState<BlobItem[]>([]);

  // 一覧再取得回数
  const [retryCount, setRetryCount] = useState(0);

  // 画面が開いた直後に呼び出されるHock
  useEffect(() => {

    // 画面レイアウト調整
    const body = document.getElementsByTagName('body')[0] as HTMLElement;
    const scriptUrl = document.createElement('script');
    scriptUrl.type = 'text/javascript';
    scriptUrl.innerText = 'console.log("add100HeightClass");remove100HeightClass();setTitleFontSize()';
    body.appendChild(scriptUrl);

    // ファイル一覧取得処理の定義
    const getBlobsInContainer = async () => {
      // パラメタが前画面から引き継げてなかったら不正な画面遷移とみなす
      if (!container || !mail || !storageAccount || !token) {
        // メールアドレス入力画面に戻る
        alert(Message.UNAUTHORIZED_ERROR);
        let parmMail: string = (mail) ? encodeURIComponent(mail) : "";
        if (container && container.length > 0) {
          navigate(`/mailcheck/${container}/${parmMail}`);
        } else {
          let undefineContainer: string = "undefined";
          navigate(`/mailcheck/${undefineContainer}/${parmMail}`);
        }
        return;
      } else {
        // パラメタチェックOKの場合
        const returnedBlobs: BlobItem[] = [];
        try {
          // 処理中ステータスにする
          setIsProcessing(true);
          // azure blob storage のAPI呼び出しでファイル一覧取得
          const containerClient: ContainerClient = new ContainerClient(
            `https://${storageAccount}.blob.core.windows.net/${container}?${token}`,
            newPipeline()
          );
          // ファイル情報1件ずつの情報をreturnedBlobs配列にPush
          for await (const blob of containerClient.listBlobsFlat()) {
            returnedBlobs.push(blob);
            // let p: BlobProperties = blob.properties;
            // console.log("contentLength:" + p.contentLength); // バイト単位
            // console.log("createdOn:" + p.createdOn);
            // console.log("expiresOn:" + p.expiresOn);
            // console.log("immutabilityPolicyExpiresOn:" + p.immutabilityPolicyExpiresOn);
            // console.log("lastAccessedOn:" + p.lastAccessedOn);
            // console.log("lastModified:" + p.lastModified);
          }
          // 処理完了ステータスにする
          setIsProcessing(false);
          // ファイル一覧を取得できなかった場合はアラート出して終了
          if (!returnedBlobs || returnedBlobs.length === 0) {
            // alert(Message.FILE_LIST_EMPTY);
            setMessage(Message.FILE_LIST_EMPTY);
          } else {
            setBlobList(returnedBlobs);
          }
        } catch (error: any) {
          // 処理完了ステータスにする
          setIsProcessing(false);
          // console.dir(error);
          // alert(Message.FILE_LIST_GET_ERROR);
          setMessage(Message.FILE_LIST_GET_ERROR);
        }
      }
    };

    //　エラーメッセージをいったんクリア 
    setMessage('');

    // ファイル一覧が空の場合はファイル一覧取得処理呼び出し
    console.log("length=" + blobList.length);
    if (!blobList || blobList.length === 0) {
      getBlobsInContainer();
    }

  }, [retryCount]);


  // ファイル名リンクをクリックした時の処理
  const downloadLinkClick = async (name: string) => {
    // SASトークンの開始日時を取得
    let decodeToken: string = decodeURIComponent(token);
    let tokenary: string[] = decodeToken.split("&");
    let st: any = tokenary.find(el => el.startsWith("st="));
    if (st) {
      // console.log("st:" + st);
      let stTime: number = Date.parse(st.substring(3));
      // console.log("stTime:" + new Date(stTime).toISOString());
      // SASトークン開始日時から３０分以上経過していたらエラーとする
      let nowTime: number = Date.now();
      // console.log("nowTime:" + new Date(nowTime).toISOString());
      if (stTime + 30 * 60 * 1000 < nowTime) {
        // メールアドレス入力画面に戻る
        let parmMail: string = (storageAccessInfoType.inputMail) ? encodeURIComponent(storageAccessInfoType.inputMail) : "";
        alert(Message.SAS_TOKEN_CREATE_ERROR);
        navigate(`/mailcheck/${container}/${parmMail}`);
        return;
      }
    }
    const element = document.createElement("a");
    let filename: string = encodeURIComponent(name);
    element.href = `https://${storageAccount}.blob.core.windows.net/${container}/${filename}?${token}`;
    element.download = filename;
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  // 「ログアウト」リンク押下時の処理を定義
  const logoutLinkClick = async () => {
    // 画面に表示されているエラーメッセージはいったんクリア
    setMessage("");
    // SASトークンクリア
    setToken("");
    // Storageaccount名クリア
    setStorageAccount("");
    // メールアドレス入力画面に戻る
    let parmMail: string = (storageAccessInfoType.inputMail) ? encodeURIComponent(storageAccessInfoType.inputMail) : "";
    navigate(`/mailcheck/${container}/${parmMail}`);
  };

  // 画面描画
  return (
    <>

      {isProcessing &&
        <div id="processing" className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Processing...</span>
          </Spinner>
        </div>
      }

      <div className="flex-grow-1 flex-shrink-0">
        <header className="navbar border-bottom-2 border-primary fixed-top" style={{ position: 'sticky' }}>
          <div className="navbar-brand">
            <span className="fujitsu-logo">FUJITSU</span>
            <span id="titleHeaderAppName" className="TitleHeaderAppName">{t("headerTitle")}</span>
          </div>
          <div id="signinInfo">{mail}&nbsp;&nbsp;
            <OverlayTrigger
              key="logout"
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-logout">
                  <strong>{t("logout")}</strong>
                </Tooltip>
              }
            >
              <span className="logoutIcon" onClick={() => logoutLinkClick()}>
                <LogoutIcon />
              </span>
            </OverlayTrigger>
          </div>
          {/* <button type="button" className="link-button" onClick={() => logoutLinkClick()} >{t("logout")}</button> */}
        </header>
        <div className="layout" style={{ paddingBottom: 7 + '%' }}>
          <div role="main" className="py-4 px-2 px-md-4 px-xl-7 FileListPanelStyle" style={{ marginTop: 5 + 'px' }} >
            <div className="d-flex align-items-center">
              <h3 id="listHeaderTitle" className="page-header-title">{t("uploadFileList")}</h3>
            </div>
            <div className="row">
              <div className="col-sm-6 mb-2 d-flex align-items-center">
                <span className="font-weight-bold">{blobList.length}</span>
                <span className="ml-1">{t("file")}</span>
              </div>
            </div>
            <ul className="list-group">
              <li className="list-group-item d-flex" id="FileListTitleHeader">
                <div className="col-auto mt-1">
                  <HeaderFileIcon />
                </div>
                <div className="col" style={{ minWidth: 0 }} >
                  <div className="row align-items-center h-100" >
                    <div className="col-md-7 text-truncate">
                      <div>{t("filename")}</div>
                    </div>
                    <div className="col-md-3" >{t("lastModified", { locale: 'JST' })}</div>
                    <div className="col-md FileSizeCol" >{t("fileSize")}</div>
                  </div>
                </div>
              </li>
              {blobList.map((item: BlobItem) => (
                <Fragment key={item.name}>
                  <li className="list-group-item d-flex FileListDataRow">
                    <div className="col-auto mt-1 text-truncate">
                      <FileIcon filename={item.name} /> &nbsp;
                    </div>
                    <div className="col" style={{ minWidth: 0 }} >
                      <div className="row align-items-center h-100">
                        <div className="col-md-7 text-truncate">
                          <div className="FileNameCol ">
                            <span className="link-button-hoveronly-underline FileNameCol" onClick={() => downloadLinkClick(`${item.name}`)} >{item.name}</span>
                            <OverlayTrigger
                              key={item.name}
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-${item.name}`}>
                                  <strong>{t("download")}</strong>
                                </Tooltip>
                              }
                            >
                              <span className="col-md-auto fileDownloadIcon" onClick={() => downloadLinkClick(`${item.name}`)} >
                                <DownloadIcon />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </div>
                        {/* <div className="col-md-auto"><DownloadIcon /></div> */}
                        <div className="col-md-3 ">{dateFormatConcertToLocalDate(item.properties.lastModified)}</div>
                        <div className="col-md  FileSizeCol ">{(item.properties?.contentLength) ? `${fileSizeUnitConcert(item.properties.contentLength, false, 2)}` : ''}</div>
                      </div>
                    </div>
                  </li>
                </Fragment>
              ))}
            </ul>
            {(message) &&
              <div>
                <span className="fileListGetErrorMessage">{message}&nbsp; </span>
                {(message === Message.FILE_LIST_GET_ERROR) &&
                  <button type="button" className="link-button" onClick={() => setRetryCount(retryCount + 1)} >{t("listGetRetry")}</button>
                }
              </div>
            }
          </div>
        </div>
        <div className="mt-8 p-4 footer_area ">© Fujitsu Limited 2023</div>
      </div>
    </>
  );

};
