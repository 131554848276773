import { IconContext } from 'react-icons';
import { BsFileEarmarkTextFill } from 'react-icons/bs';

// その他ファイル用アイコン
const OtherFileIcon: React.FC = () => {
  return (
    //valueにcolorとsizeの値を指定して渡す
    <IconContext.Provider value={{size: '24px' }}>
      <BsFileEarmarkTextFill />
    </IconContext.Provider>
  )
}

export default OtherFileIcon