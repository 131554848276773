import React from 'react';
import { Routes, Route } from "react-router-dom";
import { MailInput } from "./pages/MailInput";
import { PasswordInput } from "./pages/PasswordInput";
import { DownloadFileList } from "./pages/DownloadFileList";
import { BounceDelete } from "./pages/BounceDelete";
import { NoMatch } from "./pages/NoMatch";
import './App.css';

function App() {
  return (
    <div className="App">
      <>
        <Routes>
          <Route path="/mailcheck/:container" element={<MailInput />} >
            <Route path=":inputmail" element={<MailInput />} >
              {/* <Route path=":mail" element={<MailInput />} > */}
            </Route>
          </Route>
          <Route path="/passwordInput" element={<PasswordInput />} />
          <Route path="/downloadFileList" element={<DownloadFileList />} />
          <Route path="/bounceDelete" element={<BounceDelete />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </>
    </div>





  );
}

export default App;
