import i18n from "../i18n/configs";

// 対応ロケール一覧
export const availableLocales: string[] = ["ja", "en"];

// メッセージ用Locale取得
let userLanguage: string = window.navigator.language || "en"; // デフォルトは英語
if (userLanguage || userLanguage.length >= 2) {
  userLanguage = userLanguage.substring(0, 2);
}
if (!availableLocales.includes(userLanguage)) {
  userLanguage = "en";
}
i18n.changeLanguage(userLanguage);

// API通信結果ステータス
namespace Api_Status {
  export const OK: string = "0";
  export const CONTAINER_NOTFOUND: string = "1";
  export const MAIL_NOTFOUND: string = "2";
  export const PASSCODE_CREATE_ERROR: string = "3";
  export const PASSCODE_AUTH_ERROR: string = "3";
  export const MAIL_ADDRESS_BOUNCE_NOTHIING_ERROR: string = "3";
  export const MAIL_SEND_ERROR: string = "4";
  export const SAS_TOKEN_CREATE_ERROR: string = "4";
  export const MAIL_ADDRESS_BOUNCE_LIST_DELETE_ERROR: string = "4";
  export const PASSCODE_LOCK_ERROR: string = "5";
  export const PASSCODE_CREATE_LOCK_ERROR: string = "6";
  export const MAIL_ADDRESS_BOUNCE_ERROR: string = "7";
  export const UNAUTHORIZED_ERROR: string = "97";
  export const SYSTEM_ERROR: string = "98";
  export const UNDER_MAINTENANCE: string = "99";
}
export default Api_Status;

// メッセージ
export namespace Message {
  export const MAIL_FORMAT_ERROR = i18n.t("Message.MAIL_FORMAT_ERROR"); //"メールアドレスは正しいメールの形式（例：user@fence.com）である必要があります。";
  export const MAIL_NOTFOUND = i18n.t("Message.MAIL_NOTFOUND"); //"このメールアドレスは、共有先として設定されておりません。";
  export const MAIL_REQUIRED = i18n.t("Message.MAIL_REQUIRED"); //"メールアドレスを入力してください。";
  export const CONTAINER_NOTFOUND = i18n.t("Message.CONTAINER_NOTFOUND"); //"ダウンロードファイル用リンクが存在しません。";
  export const PASSCODE_CREATE_ERROR = i18n.t("Message.PASSCODE_CREATE_ERROR"); //"ワンタイムパスワードを設定出来ませんでした。";
  // export const PASSCODE_CREATE_ERROR = i18n.t("Message.PASSCODE_CREATE_ERROR");   //"確認コードを設定出来ませんでした。";
  export const MAIL_SEND_ERROR = i18n.t("Message.MAIL_SEND_ERROR"); //"ワンタイムパスワード通知メールを送信できませんでした。";
  // export const MAIL_SEND_ERROR = i18n.t("Message.MAIL_SEND_ERROR");   //"確認コード通知メールを送信できませんでした。";
  export const SYSTEM_ERROR = i18n.t("Message.SYSTEM_ERROR"); //"予期せぬサーバーエラーが発生しました。";
  export const UNDER_MAINTENANCE = i18n.t("Message.UNDER_MAINTENANCE"); //"システムメンテナンスです。しばらく待ってから、再度お試しください。";
  export const PASSCODE_REQUIRED = i18n.t("Message.PASSCODE_REQUIRED"); //"ワンタイムパスワードを入力してください。";
  export const PASSCODE_REQUIRED_PLACEFOLDER = i18n.t(
    "Message.PASSCODE_REQUIRED_PLACEFOLDER"
  ); //"コードを入力してください。";
  export const PASSCODE_SEND = i18n.t("Message.PASSCODE_SEND"); //"ワンタイムパスワード通知メールを送信しました。";
  export const PASSCODE_SEND_RETRY = i18n.t("Message.PASSCODE_SEND_RETRY"); //"ワンタイムパスワード通知メールを再送信しました。";
  export const PASSCODE_AUTH_ERROR = i18n.t("Message.PASSCODE_AUTH_ERROR"); //"このワンタイムパスワードは有効ではありません。";
  export const SAS_TOKEN_CREATE_ERROR = i18n.t(
    "Message.SAS_TOKEN_CREATE_ERROR"
  ); //"アップロードされたファイルにアクセス出来ませんでした。";
  export const FILE_LIST_GET_ERROR = i18n.t("Message.FILE_LIST_GET_ERROR"); //"ファイル一覧を取得できませんでした。";
  export const FILE_LIST_EMPTY = i18n.t("Message.FILE_LIST_EMPTY"); //"ダウンロード可能なファイルはありません。";
  export const FILE_DOWNLOAD_ERROR = i18n.t("Message.FILE_DOWNLOAD_ERROR"); //"ファイルをダウンロードできませんでした。";
  export const PASSCODE_LOCK_ERROR = i18n.t("Message.PASSCODE_LOCK_ERROR"); //"パスワード試行可能回数を超えました。アカウントをロックします。しばらく待ってからパスワードを再送信してください。";
  export const PASSCODE_CREATE_LOCK_ERROR = i18n.t(
    "Message.PASSCODE_CREATE_LOCK_ERROR"
  ); //"ワンタイムパスワード通知可能回数を超えました。しばらく待ってから再度パスワード発行依頼を行ってください。";
  export const UNAUTHORIZED_ERROR = i18n.t("Message.UNAUTHORIZED_ERROR"); //"不正な画面遷移です。";
  export const MAIL_ADDRESS_BOUNCE_NOTHIING_ERROR = i18n.t(
    "Message.MAIL_ADDRESS_BOUNCE_NOTHIING_ERROR"
  ); //"送信エラー登録情報が見つかりませんでした。",
  export const MAIL_ADDRESS_BOUNCE_LIST_DELETE_ERROR = i18n.t(
    "Message.MAIL_ADDRESS_BOUNCE_LIST_DELETE_ERROR"
  ); //"送信エラー登録情報を解除できませんでした。"
}

// APIのpath
export namespace ApiPath {
  // メールアドレス入力処理API
  export const API_MAIL_CHECK_DEV =
    "https://fmc-urldl-functions-development.azurewebsites.net/api/mail_address_check";
  // export const API_MAIL_CHECK = "https://fmc-urldl-functions-production.azurewebsites.net/api/mail_address_check";
  export const API_MAIL_CHECK = "/api/mail_address_check";
  // ワンタイムパスワード入力処理API
  export const API_PASSCODE_CHECK_DEV =
    "https://fmc-urldl-functions-development.azurewebsites.net/api/passcode_check";
  // export const API_PASSCODE_CHECK = "https://fmc-urldl-functions-production.azurewebsites.net/api/passcode_check";
  export const API_PASSCODE_CHECK = "/api/passcode_check";
  // バウンス解除処理API
  export const API_BOUNCE_DELETE_DEV =
    "https://fmc-urldl-functions-development.azurewebsites.net/api/bounce_delete";
  // export const API_BOUNCE_DELETE = "https://fmc-urldl-functions-production.azurewebsites.net/api/bounce_delete";
  export const API_BOUNCE_DELETE = "/api/bounce_delete";
}

// メールアドレスチェック正規表現
export namespace RegExpPattern {
  export const EmailVaildPattern: RegExp =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  export const PasscodeVaildPattern: RegExp = /^[a-zA-Z0-9]+$/;
}
