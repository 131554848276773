import axios from "axios";
import { ApiPath } from "../constant/constant";
axios.defaults.withCredentials = true;

// 認証情報項目定義（コンテナ名、メールアドレス、パスワード）
export type MailAuthInfo = {
  container: string;
  mail: string;
  passcode?: string;
};

// メールアドレス入力処理API呼び出し関数
export const mailAddressCheck = async (data: MailAuthInfo) => {
  let response;
  try {
    const hostname = window.location.hostname;
    let apiUrl: string;
    if (hostname.startsWith("localhost")) {
      console.log("localhost");
      apiUrl = ApiPath.API_MAIL_CHECK_DEV;
    } else if (
      hostname.startsWith("happy-mud-0b2c2c20f.2.azurestaticapps.net")
    ) {
      apiUrl = ApiPath.API_MAIL_CHECK_DEV;
      console.log("happy-mud-0b2c2c20f.2.azurestaticapps.net");
    } else {
      apiUrl = ApiPath.API_MAIL_CHECK;
      console.log("other");
    }
    response = await axios.post(apiUrl, data);
  } catch (error: any) {
    // console.log(error);
    // axuiosがcatchした通信エラーの場合は、エラーのresponse設定
    response =
      axios.isAxiosError(error) && error.response ? error.response : null;
  }
  return response;
};

// ワンタイムパスワード入力処理API呼び出し関数
export const passcodeCheck = async (data: MailAuthInfo) => {
  let response;
  try {
    const hostname = window.location.hostname;
    let apiUrl: string;
    if (hostname.startsWith("localhost")) {
      console.log("localhost");
      apiUrl = ApiPath.API_PASSCODE_CHECK_DEV;
    } else if (
      hostname.startsWith("happy-mud-0b2c2c20f.2.azurestaticapps.net")
    ) {
      apiUrl = ApiPath.API_PASSCODE_CHECK_DEV;
      // console.log("happy-mud-0b2c2c20f.2.azurestaticapps.net" );
    } else {
      apiUrl = ApiPath.API_PASSCODE_CHECK;
      // console.log("other" );
    }
    response = await axios.post(apiUrl, data);
  } catch (error: any) {
    console.log(error);
    // axuiosがcatchした通信エラーの場合は、エラーのresponse設定
    response =
      axios.isAxiosError(error) && error.response ? error.response : null;
  }
  return response;
};

// バウンス解除処理API呼び出し関数
export const bounceDelete = async (data: MailAuthInfo) => {
  let response;
  try {
    const hostname = window.location.hostname;
    let apiUrl: string;
    if (hostname.startsWith("localhost")) {
      console.log("localhost");
      apiUrl = ApiPath.API_BOUNCE_DELETE_DEV;
    } else if (
      hostname.startsWith("happy-mud-0b2c2c20f.2.azurestaticapps.net")
    ) {
      apiUrl = ApiPath.API_BOUNCE_DELETE_DEV;
      console.log("happy-mud-0b2c2c20f.2.azurestaticapps.net");
    } else {
      apiUrl = ApiPath.API_BOUNCE_DELETE;
      console.log("other");
    }
    response = await axios.post(apiUrl, data);
  } catch (error: any) {
    // console.log(error);
    // axuiosがcatchした通信エラーの場合は、エラーのresponse設定
    response =
      axios.isAxiosError(error) && error.response ? error.response : null;
  }
  return response;
};
